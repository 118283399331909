<template>
  <v-row justify="center">
    <v-dialog v-model="shower" persistent max-width="400">
      <v-card >
          <v-card-title v-if="status === 'N'">
          <v-avatar
            :color="color.theme"
            :dark="color.darkTheme"
            size="32"
            class="mr-4"
          >
            <v-icon :dark="color.darkTheme">delete</v-icon> </v-avatar><b>{{$t('checkdelete.wanttodelete')}}</b></v-card-title>
          <v-card-title v-else-if="status === 'Y'">
          <v-avatar
            :color="color.theme"
            :dark="color.darkTheme"
            size="30"
            class="mr-2"
          >
            <v-icon :dark="color.darkTheme">delete</v-icon> </v-avatar><b>{{$t('checkdelete.wanttodeleteandrequest')}}</b></v-card-title>
       <v-divider></v-divider>
       <br/>
        <v-card-text class="test-center">{{$t('checkdelete.cantrestore')}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="$emit('closedialog')" >{{$t('checkdelete.cancel')}}</v-btn>
          <v-btn color="green darken-1" dark @click="deleteinbox(),$emit('closedialog')">{{$t('checkdelete.delete')}}</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
  export default {
    data () {
      return {
        dialog: false,
      }
    },
    computed:{
    ...mapState(["username", "authorize", "account_active", "color","role_level"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
            console.log("data",this.data);
            console.log("status",this.status);
        }
        return this.show;
      }
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    }
    },
    props:["show","data","status"],
    methods:{

   async deleteinbox(){
      let payload = {
        account_id:this.dataAccountId,
        inbox_id:[this.data.inbox_id],
        send_request: this.status,
        delete_log:"inbox",
        user_id:this.dataUsername
      }
      console.log("payload",payload);
      let auth = await gbfGenerate.generateToken();
       await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/delete_log_inbox",
          payload,{headers: { Authorization: auth.code },}

        )
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            if(this.status === 'N'){
              Toast.fire({
              icon: "success",
              title: "Delete Success",
            });
            this.$emit('reload') 
            }else if(this.status === 'Y'){
              Toast.fire({
              icon: "success",
              title: "Delete & Request Success",
            });
            this.$emit('reload') 
            }  
                    
          }
        });
    },
    }
  }
</script>
<style>

</style>